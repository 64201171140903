import './App.css';
import Calculator from './calc';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React from 'react';

function App() {
  return (
    <div className="App">
     <Router>
            <Routes>
                <Route path="/" element={<Calculator />} />
                <Route path="/current" element={<Calculator />} />
                <Route path="/new" element={<Calculator />} />
                <Route path="/profile" element={<Calculator />} />
                <Route path="/notes" element={<Calculator />} />
            </Routes>
        </Router>
    </div>
  );
}

export default App;
