import React, { useState, useEffect, useCallback } from "react";
import Slider from '@mui/material/Slider';
import ssbclogo from './assets/summit-logo-white-square.png';
import house from './assets/PngItem_766038.png';
import './App.css';
import { useNavigate, useLocation } from "react-router-dom";

function Calculator() {
    const navigate = useNavigate();
    const location = useLocation();

    const [initialTracked, setInitialTracked] = useState(false);

    const handleNavigation = useCallback((targetScreen, replace = false) => {
        if (location.pathname.slice(1) !== targetScreen) {
            navigate(`/${targetScreen}`, { replace });
        }
    }, [navigate, location.pathname]);

    useEffect(() => {
        const path = location.pathname.slice(1) || "current"; 
        setScreen(path);

        if (!initialTracked) {
            handleNavigation(path, true);
            setInitialTracked(true);
        }
    }, [location.pathname, initialTracked, handleNavigation]);

    const [screen, setScreen] = useState("current");
    const [notes, setNotes] = useState("");
    const [data, setData] = useState({
        "# of days I work per week": {"Current": "", "New": 0, "Difference": 0, "min": 0, "max": 7, "step": 1, "style":{ style: 'decimal', maximumFractionDigits: 0}, "suffix": "", "valid": true},
        "Avg # of daily guests I have": {"Current": "", "New": 0, "Difference": 0, "min": 0, "max": 20, "step": .5, "style":{ style: 'decimal', minimumFractionDigits: 1, maximumFractionDigits: 1 }, "suffix": "", "valid": true},
        "Avg Service Ticket": {"Current": "", "New": 0, "Difference": 0, "min": 30, "max": 300, "step": 5, "style":{ style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }, "suffix": "", "valid": true},
        "My service commission %": {"Current": "", "New": 0, "Difference": 0, "min": 40, "max": 55, "step": 1, "style":{ style: 'decimal', maximumFractionDigits: 0}, "suffix": "%", "valid": true},
        "My avg tip %": {"Current": 15, "New": 15, "Difference": 0, "min": 0, "max": 30, "step": 1, "style":{ style: 'decimal', maximumFractionDigits: 0}, "suffix": "%", "valid": true},
    });

    const [profileData, setProfileData] = useState ({
        "Daily Guest Count": {"Current": 0, "New": 0, "Difference": 0, "style":{ style: 'decimal', minimumFractionDigits: 1, maximumFractionDigits: 1 }},
        "Avg Service Ticket": {"Current": "", "New": 0, "Difference": 0, "style":{ style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }},
        "Daily Service": {"Current": 0, "New": 0, "Difference": 0, "style":{ style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }},
        "Daily Retail": {"Current": 0, "New": 0, "Difference": 0, "style":{ style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }},
        "Daily Tips": {"Current": 0, "New": 0, "Difference": 0, "style":{ style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }},
        "Monthly Service Commission": {"Current": 0, "New": 0, "Difference": 0, "style":{ style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }},
        "Monthly Service Tips": {"Current": 0, "New": 0, "Difference": 0, "style":{ style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }},
        "Monthly Retail Bonus": {"Current": 0, "New": 0, "Difference": 0, "style":{ style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }},
        "Total Income": {"Current": 0, "New": 0, "Difference": 0, "style":{ style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }},
    });

    useEffect(() => {
        const calculateServiceCommission = (type) => {
            const daysWorked = data["# of days I work per week"][type];
            const clientsServed = data["Avg # of daily guests I have"][type];
            const weeksWorked = 50;
            const serviceTicketAverage = data["Avg Service Ticket"][type];
            const commissionPercentage = data["My service commission %"][type]/100;
            return daysWorked * clientsServed * weeksWorked * serviceTicketAverage * commissionPercentage;
        };

        const calculateServiceTips = (type) => {
            const serviceTotal = data["# of days I work per week"][type] * data["Avg # of daily guests I have"][type] * 50 * data["Avg Service Ticket"][type];
            const averageTipPercentage = data["My avg tip %"][type]/100;
            return serviceTotal * averageTipPercentage;
        };

        const calculateRetailBonus = (type) => {
            const weeklyServiceTotal = data["# of days I work per week"][type] * data["Avg # of daily guests I have"][type] * data["Avg Service Ticket"][type];
            return (weeklyServiceTotal * .15) * .15 * 50;
        };

        const calculateDailyServiceGoal = (type) => {
            return data["Avg Service Ticket"][type] * data["Avg # of daily guests I have"][type];
        };

        const calculateDailyRetailGoal = (type) => {
            const weeklyServiceTotal = data["# of days I work per week"][type] * data["Avg # of daily guests I have"][type] * data["Avg Service Ticket"][type];
            if (data["# of days I work per week"][type] === 0) {
                return 0;
            }
            return (weeklyServiceTotal * .15) / data["# of days I work per week"][type];
        };

        const serviceCommissionCurrent = calculateServiceCommission("Current");
        const serviceCommissionNew = calculateServiceCommission("New");
        const serviceTipsCurrent = calculateServiceTips("Current");
        const serviceTipsNew = calculateServiceTips("New");
        const retailBonusCurrent = calculateRetailBonus("Current");
        const retailBonusNew = calculateRetailBonus("New");
        const dailyServiceGoalCurrent = calculateDailyServiceGoal("Current");
        const dailyServiceGoalNew = calculateDailyServiceGoal("New");
        const dailyRetailGoalCurrent = calculateDailyRetailGoal("Current");
        const dailyRetailGoalNew = calculateDailyRetailGoal("New");

        setProfileData(prevProfileData => ({
            ...prevProfileData,
            "Avg Service Ticket": {
                ...prevProfileData["Avg Service Ticket"],
                "New": data["Avg Service Ticket"].New,
                "Current": data["Avg Service Ticket"].Current,
                "Difference": data["Avg Service Ticket"].New - data["Avg Service Ticket"].Current,
            },
            "Monthly Service Commission": {
                ...prevProfileData["Monthly Service Commission"],
                "New": serviceCommissionNew/12,
                "Current": serviceCommissionCurrent/12,
                "Difference": serviceCommissionNew/12 - serviceCommissionCurrent/12,
            },
            "Daily Guest Count": {
                ...prevProfileData["Daily Guest Count"],
                "New": data["Avg # of daily guests I have"].New,
                "Current": data["Avg # of daily guests I have"].Current,
                "Difference": data["Avg # of daily guests I have"].New - data["Avg # of daily guests I have"].Current,
            },
            "Monthly Service Tips": {
                ...prevProfileData["Monthly Service Tips"],
                "New": serviceTipsNew/12,
                "Current": serviceTipsCurrent/12,
                "Difference": serviceTipsNew/12 - serviceTipsCurrent/12,
            },
            "Monthly Retail Bonus": {
                ...prevProfileData["Monthly Retail Bonus"],
                "New": retailBonusNew/12,
                "Current": retailBonusCurrent/12,
                "Difference": retailBonusNew/12 - retailBonusCurrent/12,
            },
            "Total Income": {
                ...prevProfileData["Total Income"],
                "New": (serviceCommissionNew + serviceTipsNew + retailBonusNew)/12,
                "Current": (serviceCommissionCurrent + serviceTipsCurrent + retailBonusCurrent)/12,
                "Difference": (serviceCommissionNew + serviceTipsNew + retailBonusNew)/12 - (serviceCommissionCurrent + serviceTipsCurrent + retailBonusCurrent)/12,
            },
            "Daily Service": {
                ...prevProfileData["Daily Service"],
                "New": dailyServiceGoalNew,
                "Current": dailyServiceGoalCurrent,
                "Difference": dailyServiceGoalNew - dailyServiceGoalCurrent,
            },
            "Daily Retail": {
                ...prevProfileData["Daily Retail"],
                "New": dailyRetailGoalNew,
                "Current": dailyRetailGoalCurrent,
                "Difference": dailyRetailGoalNew - dailyRetailGoalCurrent,
            },
            "Daily Tips": {
                ...prevProfileData["Daily Tips"],
                "New": dailyServiceGoalNew * .15,
                "Current": dailyServiceGoalCurrent * .15,
                "Difference": dailyServiceGoalNew * .15 - dailyServiceGoalCurrent * .15,
            },
        }));
    }, [data]);

    useEffect(() => {
        // Scroll to the top whenever the screen state changes
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.getElementById('outer').scrollTo(0, 0)
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0; // For Safari

    }, [screen]);

    const handleInputChangeForm = (category, type, value) => {
        const updatedValue = value === '' ? '' : Number(value);
        const updatedCategory = {
            ...data[category],
            [type]: updatedValue,
            "New": updatedValue,
            "valid": true,
        };

        setData(prevData => ({
            ...prevData,
            [category]: updatedCategory,
        }));
    };

    const handleInputChangeSlider = (category, type, value) => {
        const updatedValue = value === '' ? '' : Number(value);
        const updatedCategory = {
            ...data[category],
            [type]: updatedValue,
            "New": updatedValue,
            "Difference": updatedValue - data[category].Current,
            "valid": true,
        };

        setData(prevData => ({
            ...prevData,
            [category]: updatedCategory
        }));
    };

    const handleNotesChange = (e) => {
        setNotes(e.target.value);
    }

    return (
        <div className="outer" id="outer">
            {screen === "current" ?
                <>
                    <div style={{display: "flex", justifyContent: "center", flexDirection: "row"}}>
            <div style={{display: "flex", justifyContent: "flex-start", flexDirection: "column", padding: "10px", margin: "10px", height: "100%", width: "70%"}}>
                <div style={{padding: "0px", margin: "0px"}}><p style={{color: "white", fontWeight: "600", fontFamily: "sans-serif", fontStyle: "normal", padding: "0px", margin: "0px", fontSize: "clamp(22px, 3vw, 25px)"}}>INCOME</p></div>
                <div style={{padding: "0px", margin: "0px"}}><p style={{color: "#eeeeee", fontWeight: "400", fontFamily: "sans-serif", fontStyle: "normal", padding: "0px", margin: "0px", fontSize: "clamp(18px, 3vw, 25px)"}}>Growth Calculator</p></div>
            </div>
            <div style={{display: "flex", justifyContent: "flex-end", flexDirection: "row", height: "100%", width: "30%", padding: "10px", margin: "10px",}}>
                <img src={ssbclogo} alt="Summit Salon Business Center" style={{width: "50px", height: "50px", opacity: "60%"}}/>  
            </div>
            </div>
                    <form noValidate onSubmit={(e) => {
                        e.preventDefault();
                        let isValid = true;
                        const newData = { ...data };

                        Object.entries(newData).forEach(([category, values]) => {
                            if (values.Current === '') {
                                isValid = false;
                                newData[category] = { ...values, valid: false };
                            } else {
                                newData[category] = { ...values, valid: true };
                            }
                        });
                        setData(newData);

                        if (!isValid) {
                            return;
                        }
                        handleNavigation("new");
                        window.scrollTo({ top: 0});
                    }} style={{padding: "10px", backgroundColor:"white", paddingTop:"20px", borderRadius: "15px", margin: "0px 15px 15px 15px"}}>

                        {Object.entries(data).map(([category, values]) => 
                            ["My avg tip %", "My avg retail $ per week", "My avg retail commission %", "# of weeks I work per year"].includes(category) ? null : (
                                <div key={category} className="column-row" style={{display: "flex", flexDirection: "column", justifyContent:"center"}}>
            
                                <div style={{width: "100%", display:"flex", justifyContent:"flex-start"}}>
                                    <h1 className="category-top" style={{ fontSize: "clamp(12px, 3vw, 16px)"}}>{category}*</h1>
                                </div>
                                <div style={{width: "100%", display:"flex", justifyContent:"center"}}>
                                
                                    <input
                                        style={{padding: "10px", borderRadius:"20px", width: "100%", margin: "10px", fontSize: "clamp(16px, 3vw, 16px)"}}
                                        type="number"
                                        className={`currentInput ${values.valid ? "" : "invalid"} no-spinner`} // Add the invalid class if the field is invalid
                                        value={values.Current === "" ? "" : values.Current}
                                        onChange={e => handleInputChangeForm(category, "Current", e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                        ))}

<div style={{display: "flex", justifyContent: "center"}}>
        <button type="submit" className="submit-button" style={{fontSize: "clamp(12px, 3vw, 16px)"}} onClick={() => { 
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }); 
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, Opera
    }}>Next</button>
    </div>
                    </form>
                </> : null}

            {screen === "new" || screen === "profile" || screen === "notes" ? 
            <>
                <div className="stick" id="stick">
                    <div style={{ padding: "0px 10px 5px 10px", borderRadius: "15px 15px 0px 0px", display:"flex", justifyContent:"center", flexDirection:"column"  }}>
                        <div style={{display: "flex", justifyContent: "space-between", flexDirection: "row"}}>
                            <div style={{display: "flex", justifyContent: "center", flexDirection: "row", margin: "10px", width: "20%", alignItems: "center"}}>
                                <button className="next-button" style={{backgroundColor: "transparent", border: "0px", cursor: "pointer"}} onClick={() => {handleNavigation("current");  window.scrollTo({ top: 0});}}>
                                    <img src={house} alt="Home" style={{width: "30px", height: "30px"}}/>
                                </button>
                            </div>
                            <div style={{display: "flex", justifyContent: "center", flexDirection: "column", margin: "10px", width: "80%"}}>
                                <p style={{ fontSize: "clamp(12px, 2.5vw, 19px)", fontWeight: "bold", display: "flex", justifyContent: "center", margin: "0px 0px 0px 0px", padding: "0", color: "#35646b"}}>Income Growth Calculator</p>
                            </div>
                            <div style={{display: "flex", justifyContent: "center", flexDirection: "row", margin: "10px", width: "20%", alignItems:"center"}}>
                                <a href="https://summitsalon.com" target="_blank" rel="noopener noreferrer">
                                    <button className="next-button" style={{backgroundColor: "transparent", border: "0px", cursor: "pointer"}}>
                                        <img src={ssbclogo} alt="Summit Salon Business Center" style={{width: "30px", height: "30px", opacity:"60%"}}/>
                                    </button>
                                </a>  
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "0px", marginBottom: "-25px", backgroundColor: "white", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", borderRadius: "15px", flexDirection: "column"}}>
                        <div style={{ display: "flex", justifyContent: "space-between", width:"90%", alignItems: "center", marginTop: "0px", marginBottom: "10px", flexDirection: "row"}}>
                            <div style={{ display: "flex", width: "100%", flexDirection: "column", padding: "15px 0px 5px 0px", alignItems: "flex-start", paddingTop: "10px"}}>
                                <p style={{ fontSize: "clamp(30px, 5vw, 37px)", fontWeight: "bold", display: "flex", justifyContent: "center", margin: "0px 0px 0px 0px", padding: "0", color: "#35646b"}}>
                                    {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 } ).format(profileData["Total Income"]?.New || 0)}
                                </p>
                                <p style={{ fontSize: "clamp(10px, 2.5vw, 16px)", color: "#55959f", opacity: "90%", fontWeight: "bold", display: "flex", justifyContent: "center", margin: "0px 0px 0px 0px", padding: "0" }}>Monthly Income Goal</p>
                            </div>
                            <div style={{ display: "flex", width: "100%", flexDirection: "column", padding: "15px 0px 5px 0px", alignItems: "flex-end", paddingTop: "10px"}}>
                                <p style={{ fontSize: "clamp(30px, 5vw, 37px)", fontWeight: "bold", display: "flex", justifyContent: "center", margin: "0px 0px 0px 0px", padding: "0", color: "#35646b"}}>
                                    {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 } ).format(profileData["Total Income"]?.New * 12 || 0)}
                                </p>
                                <p style={{ fontSize: "clamp(10px, 2.5vw, 16px)", color: "#55959f", opacity: "90%", fontWeight: "bold", display: "flex", justifyContent: "center", margin: "0px 0px 0px 0px", padding: "0" }}>Annual Income Goal</p>
                            </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between", width:"90%", alignItems: "center", marginTop: "0px", flexDirection: "row", backgroundColor:"black", opacity:"10%", height:"2px"}}></div>
                            <div style={{ display: "flex", justifyContent: 'center', alignItems: "flex-start", marginBottom: "20px", width: "90%"}}>
                                <div style={{ display: "flex", width: "100%", flexDirection: "column", padding: "15px 0px 5px 0px", alignItems: "flex-start"}}>
                                    <p style={{ fontSize: "clamp(12px, 2.5vw, 16px)", color: "#55959f", opacity: "60%", fontStyle: "italic", fontWeight: "bold", display: "flex", justifyContent: "center", margin: "0px 0px 5px 0px", padding: "0" }}>Current Income</p>
                                    <p style={{ fontSize: "clamp(8px, 2.5vw, 12px)", color: "#ea772a", opacity: "60%", fontWeight: "bold", display: "flex", justifyContent: "center", margin: "2px 0px 0px 0px", padding: "0" }}>Monthly</p>
                                    <p style={{ fontSize: "clamp(14px, 5vw, 22px)", color: "#55959f", fontWeight: "bold", display: "flex", justifyContent: "center", margin: "1px 0px 5px 0px", padding: "0" }}>
                                        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0  }).format(profileData["Total Income"]?.Current || 0)}
                                    </p>
                                    <p style={{ fontSize: "clamp(8px, 2.5vw, 12px)", color: "#ea772a", opacity: "60%", fontWeight: "bold", display: "flex", justifyContent: "center", margin: "2px 0px 0px 0px", padding: "0" }}>Yearly</p>
                                    <p style={{ fontSize: "clamp(14px, 5vw, 22px)", color: "#55959f", fontWeight: "bold", display: "flex", justifyContent: "center", margin: "1px 0px 5px 0px", padding: "0" }}>
                                        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0  }).format(profileData["Total Income"]?.Current * 12 || 0)}
                                    </p>
                                </div>
                                <div style={{ display: "flex", width: "100%", flexDirection: "column", padding: "15px 0px 5px 0px", alignItems: "flex-end"}}>
                                    <p style={{ fontSize: "clamp(12px, 2.5vw, 16px)", color: "#55959f", opacity: "60%", fontStyle: "italic", fontWeight: "bold", display: "flex", justifyContent: "center", margin: "0px 0px 5px 0px", padding: "0" }}>Income Growth</p>
                                    <p style={{ fontSize: "clamp(8px, 2.5vw, 12px)", color: "#ea772a", opacity: "60%", fontWeight: "bold", display: "flex", justifyContent: "center", margin: "2px 0px 0px 0px", padding: "0" }}>Monthly</p>
                                    <p style={{ fontSize: "clamp(14px, 5vw, 22px)", color: "#55959f", fontWeight: "bold", display: "flex", justifyContent: "center", margin: "1px 0px 5px 0px", padding: "0" }}>
                                    {profileData["Total Income"]?.Difference >= 0  ? "+" : null}{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 } ).format(profileData["Total Income"]?.Difference || 0)}
                                    </p>
                                    <p style={{ fontSize: "clamp(8px, 2.5vw, 12px)", color: "#ea772a", opacity: "60%", fontWeight: "bold", display: "flex", justifyContent: "center", margin: "2px 0px 0px 0px", padding: "0" }}>Yearly</p>
                                    <p style={{ fontSize: "clamp(14px, 5vw, 22px)", color: "#55959f", fontWeight: "bold", display: "flex", justifyContent: "center", margin: "1px 0px 5px 0px", padding: "0" }}>
                                    {profileData["Total Income"]?.Difference >= 0  ? "+" : null}{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 } ).format(profileData["Total Income"]?.Difference * 12 || 0)}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center"}}>
                            <button className={`nav-button ${screen === 'new' ? 'active-state' : ''}`}  onClick={() => {handleNavigation("new"); window.scrollTo({ top: 0});}}>Potential</button>
                            <button className={`nav-button ${screen === 'profile' ? 'active-state' : ''}`} onClick={() => {handleNavigation("profile"); window.scrollTo({ top: 0});}}>New Goals</button>
                            <button className={`nav-button ${screen === 'notes' ? 'active-state' : ''}`} onClick={() => {handleNavigation("notes"); window.scrollTo({ top: 0});}}>Notes</button>
                        </div>
                    </div>

                    {screen === "profile" && <div style={{margin: "30px, 0px", borderRadius: "30px" }}>
                        <div className="column-header-bottom" style={{padding: "10px 0px 0px 10px"}}>
                            <div className="name-title" style={{color: "white" }}></div>
                            <div className="kpi-column-header"><p className="current-title" style={{color: "white"}}>Current</p></div>
                            <div className="kpi-column-header"><p className="new-title" style={{color: "white" }}>Goal</p></div>
                            <div className="kpi-column-header"><p className="difference-title" style={{color: "white" }}>+/-</p></div>
                        </div>
                        {Object.entries(profileData).map(([category, values]) => 
                            category === "Total Income" ? null : (
                                <>
                                    <div key={category} className="column-row" style={{backgroundColor: "white", borderRadius: "10px"}}>
                                        <h1 className="category-bottom" >{category}</h1>
                                        <div className="kpi-column"><p className="current" style={{padding:"0px", border: "0px"}}>{new Intl.NumberFormat('en-US', values.style ).format(values.Current)}</p></div>
                                        <div className="kpi-column"><p className="new" style={{padding:"0px", border: "0px"}}>{new Intl.NumberFormat('en-US', values.style ).format(values.New)}</p></div>
                                        <div className="kpi-column"><p className="difference" style={{padding: "0px", border: "0px", fontWeight: "bold", display: "flex", justifyContent: "center", alignItems: "center", width:"100%", color: values.Difference >= 0  ? "#8FBC8F" : "#F08080"}}>{values.Difference >= 0  ? "+" : null}{new Intl.NumberFormat('en-US', values.style ).format(values.Difference)}</p></div>
                                    </div>
                                    <div className="column-row" style={{height: "3px", padding: "0px 0px 0px 10px"}}>
                                        <div className="name-title" style={{height:"0"}}></div>
                                        <div className="kpi-column-fill"></div>
                                        <div className="kpi-column-fill"></div>
                                        <div className="kpi-column-fill"></div>
                                    </div>
                                </>
                        ))}
                        <div style={{width: "100%", height: "40px"}}></div>
                    </div>}

                    {screen === "notes" && 
                        <div style={{background:"none", height:"400px"}} className="notes">
                            <p className="lower-title" style={{ width: "95%",fontSize: "2em", fontWeight: "bold", margin:"5px 0px", color:"white", background:"none"}}>Notes</p>
                            <textarea
                                value={notes}
                                onChange={handleNotesChange}
                                className="notes-text"
                                placeholder="Enter notes here..."
                                name="notes"
                                rows="25"
                                cols="50"
                                style={{ width: "85%", height: "100%", borderRadius:"20px", padding:"20px", marginBottom:"20px", fontSize: "1.2em", resize: "none" , backgroundColor: "white", color: "black", overflow: "auto"}}
                            ></textarea>
                        </div>}

                    {screen === "new" && 
                        <div style={{padding: "0px", borderRadius: "0px 0px 15px 15px"}}>
                            {Object.entries(data).map(([category, values]) =>
                                category === "My avg tip %" ? null : (
                                    <div key={category} className="column-row" style={{ display: "flex", flexDirection: "column", padding: "0px 0px", backgroundColor:"white", borderRadius:"10px", margin:"10px 10px"}}>
                                        <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", width:"90%" }}>
                                            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "row", margin: "0px 50px 0px 0px", width: "100%" }}>
                                                <p style={{padding:"0px", marginTop:"12px", marginBottom:"12px", fontSize: "clamp(12px, 3vw, 16px)", width:"100%"}}>{category}</p>
                                            </div>
                                            <div style={{ width: "15%", display: "flex", justifyContent: "flex-end", alignItems: "center", flexDirection: "row" }}>
                                                <p style={{ fontSize: ".8em", color: "#55959f", padding:"2px", margin:"2px" }}>Diff.</p>
                                                <p style={{ fontSize: ".8em", color: values.Difference >= 0  ? "#8FBC8F" : "#F08080", padding:"2px", margin:"2px", fontWeight:"bold" }}>{values.Difference >= 0  ? "+" : ""}{new Intl.NumberFormat('en-US', values.style).format(values.Difference)}{values.suffix}</p>
                                            </div>
                                        </div>
                                        <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", marginBottom:"5px" }}>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width:"90%", margin:"auto"}}>
                                                <Slider
                                                    sx={{
                                                        '& .MuiSlider-thumb': {
                                                            marginLeft: '0px',
                                                        },
                                                        '& .MuiSlider-rail, & .MuiSlider-track': {
                                                            marginLeft: '0px',
                                                        },
                                                    }}
                                                    onChange={e => handleInputChangeSlider(category, "New", e.target.value)}
                                                    value={values.New}
                                                    min={values.min}
                                                    max={values.max}
                                                    step={values.step}
                                                    style={{ width: "100%", color: "#55959f", margin:"5px", padding: "0px" }}
                                                />
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", width:"90%", margin:"auto" }}>
                                                <div style={{ width: "25%", display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "row", margin: "5px 0px" }}>
                                                    <p style={{ fontSize: ".8em", color: "#55959f", padding:"2px", margin:"2px" }}>Curr.</p>
                                                    <p style={{ fontSize: ".8em", color: "#55959f", padding:"2px", margin:"2px" }}>{new Intl.NumberFormat('en-US', values.style).format(values.Current)}{values.suffix}</p>
                                                </div>
                                                <div style={{ width: "25%", display: "flex", justifyContent: "flex-end", alignItems: "center", flexDirection: "row" }}>
                                                    <p style={{ fontSize: ".8em", color: "#55959f", padding:"2px", margin:"2px" }}>Goal</p>
                                                    <p style={{ fontSize: ".8em", color: "#55959f", padding:"2px", margin:"2px", fontWeight:"bold" }}>{new Intl.NumberFormat('en-US', values.style).format(values.New)}{values.suffix}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>}
                </div>
                </> : null}
        </div>
    );
}

export default Calculator;
